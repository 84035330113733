*::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.cancelIcon {
  right: auto;
  position: relative;
  top: 13px;
  z-index: 1;
  fill: #ff4842;
  background: #fff;
  border-radius: 100px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.fileContainer {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  padding: 0px !important;
  margin: 0px !important;
}

.fileContainer .uploadPicturesWrapper {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.fileContainer .uploadPicturesWrapper div {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.uploadPictureContainer {
  width: 100px !important;
  height: 100px !important;
  margin: 2% 5% !important;
  padding: 10px !important;
}

.uploadPictureContainer img {
  height: 100px !important;
  width: 100px !important;
}

.errorMessage {
  font-weight: 400;
  font-size: 0.75rem;
  margin-left: 14px;
  color: #ff4842;
}

.login-form > * {
  font-family: 'Inter';
}

.image-swiper-container {
  display: inline-flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}
