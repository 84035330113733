@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;500&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Roboto Slab", serif;
}
.headerSection {
  box-sizing: border-box;
  padding: 16px;
}
.headerSection .logoContainer {
  display: block;
  margin: 0 auto;
  width: 300px;
}
.headerSection .logoContainer img {
  width: 100%;
}
.headerSection h1 {
  text-align: center;
  font-size: 24px;
  color: rgb(32, 32, 32);
  margin: 0;
}
.headerSection h3 {
  text-align: center;
}
.mainContainer {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  background-color: #ffa726;
  color: #fff;
}
.mainContainer .description,
.mainContainer .keyFeatures {
  padding: 50px;
  width: 50%;
}
.mainContainer p {
  font-weight: 300;
}
.cta {
  display: flex;
  padding: 20px;

  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.cta p {
  font-weight: 300;
}
.cta .description,
.cta .mobileScreen {
  padding: 50px;
}
.cta .btn {
  border: 1px solid #3f51b5;
  background-color: #3f51b5;
  box-shadow: 1px 2px 6px silver;
  border-radius: 6px;
  padding: 10px 16px;
  color: #fff;
  text-decoration: none;
  font-family: "Roboto Slab", serif;
}
.cta .downloadBtn {
  margin-top: 30px;
}
.cta .btn-white {
  background-color: #fff;
  color: #3f51b5;
}
@media screen and (max-width: 600px) {
  .mainContainer {
    flex-direction: column;
  }
  .mainContainer .description,
  .mainContainer .keyFeatures {
    padding: 16px;
    width: auto;
  }
  .cta {
    flex-direction: column;
  }
  .cta .description,
  .cta .mobileScreen {
    padding: 16px;
    margin-bottom: 30px;
  }
}
